import React from 'react';
import Link from 'next/link';
import Image from 'next/image';

type LogoProps = {
  logoSrc: string;
  altText?: string;
  width?: number;
  height?: number;
};

const Logo: React.FC<LogoProps> = ({
  logoSrc,
  altText = 'Logo',
  width = 120,
  height = 40,
}) => {
  return (
   
      <div className="flex items-center space-x-2 cursor-pointer">
        <Image
          src={logoSrc}
          alt={altText}
          width={width}
          height={height}
        />
      </div>
   
  );
};

export default Logo;
