"use client";
import "./globals.css";
import { Sora } from "next/font/google";
import { usePathname } from "next/navigation";

import { Navbar, Footer } from "@/components/layout";
import ClarityIntegration from "@/components/integrations/msclarity";
import GoUpButton from "@/components/widgets/GoUpButton";
import { useEffect } from "react";

const sora = Sora({ subsets: ["latin"], weight: ["400", "700"] });

// Map slugs or paths to their specific OG image data
const ogImageMap: Record<string, { url: string; alt: string }> = {
  "/figma-builder-io-to-lovable-to-ratl-native-integration": {
    url: "https://ratl.ai/ratl-design-to-test-og-image.webp",
    alt: "ratl.ai figma to dev to ratl",
  },
  // Add more mappings as needed
};

// Default OG image if no specific mapping exists
const defaultOgImage = {
  url: "https://ratl.ai/og-image.webp",
  alt: "ratl.ai banner",
};

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const pathname = usePathname(); // Get the current pathname
  const currentOgImage = ogImageMap[pathname || "/"] || defaultOgImage;

  const metadata = {
    title: "ratl.ai - AI Agents for Autonomous Testing & Software Delivery",
    description:
      "Empower your engineering team to accelerate testing, iterate faster, and confidently deliver releases using the power of AI",
    keywords: [
      "AI Testing",
      "Autonomous Testing",
      "Software Delivery",
      "AI Agents",
      "Product Reliability",
      "Load Testing",
      "API Testing",
      "End-to-End Testing",
    ],
    openGraph: {
      title: "ratl.ai - AI Agents for Autonomous Testing & Software Delivery",
      description:
        "Empower your engineering team to accelerate testing, iterate faster, and confidently deliver releases using the power of AI",
      url: `https://ratl.ai${pathname || "/"}`,
      images: [
        {
          url: currentOgImage.url,
          width: 1200,
          height: 630,
          alt: currentOgImage.alt,
        },
      ],
      siteName: "ratl.ai",
      type: "website",
    },
    twitter: {
      card: "summary_large_image",
      title: "ratl.ai - AI Agents for Autonomous Testing & Software Delivery",
      description:
        "Empower your engineering team to accelerate testing, iterate faster, and confidently deliver releases using the power of AI",
      images: [currentOgImage.url],
    },
  };

  const googleAnalyticsId = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID;

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const faviconEl = document.getElementById(
      "favicon"
    ) as HTMLLinkElement | null;
    if (!faviconEl) return;

    const updateFavicon = () => {
      faviconEl.href = mediaQuery.matches
        ? "/ratl/logo/ratl_icon_white.png"
        : "/ratl/logo/ratl_icon_black.png";
    };

    updateFavicon();

    mediaQuery.addEventListener("change", updateFavicon);
    return () => {
      mediaQuery.removeEventListener("change", updateFavicon);
    };
  }, []);

  return (
    <html lang="en">
      <head>
        {/* Basic SEO Meta Tags */}
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />
        <meta name="keywords" content={metadata.keywords.join(", ")} />
        <meta name="author" content="ratl.ai Team" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={metadata.openGraph.title} />
        <meta
          property="og:description"
          content={metadata.openGraph.description}
        />
        <meta property="og:url" content={metadata.openGraph.url} />
        <meta property="og:type" content={metadata.openGraph.type} />
        <meta property="og:site_name" content={metadata.openGraph.siteName} />
        {metadata.openGraph.images.map((image, index) => (
          <meta
            key={`og:image:${index}`}
            property="og:image"
            content={image.url}
          />
        ))}

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content={metadata.twitter.card} />
        <meta name="twitter:title" content={metadata.twitter.title} />
        <meta
          name="twitter:description"
          content={metadata.twitter.description}
        />
        {metadata.twitter.images.map((image, index) => (
          <meta
            key={`twitter:image:${index}`}
            name="twitter:image"
            content={image}
          />
        ))}

        {/* Canonical URL */}
        <link rel="canonical" href={metadata.openGraph.url} />

        {/* Favicon and Apple Touch Icons */}
        <link
          id="favicon"
          rel="icon"
          href="/ratl/logo/ratl_icon_black.png"
          type="image/png"
        />

        <link
          rel="apple-touch-icon"
          href="/ratl/logo/ratl_icon_white.png"
          sizes="180x180"
        />

        {/* Preconnect for Performance */}
        <link rel="preconnect" href="https://www.googletagmanager.com" />

        {/* Google Analytics */}
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${googleAnalyticsId}');
            `,
          }}
        ></script>
      </head>
      <body className={sora.className}>
        <ClarityIntegration />
        <main className="bg-gradient from-[#020014] via-[#8989A8] to-[#646283]">
          <div className="relative z-50">
            <Navbar />
          </div>
          {children}
          <Footer />
          <GoUpButton />
        </main>
      </body>
    </html>
  );
}
