import React, { memo } from "react";
import { motion } from "framer-motion";

const Stars = () => {
  const randomMove = () => Math.random() * 10; // Random speed
  const randomStart = () => Math.random() * 100; // Start position

  return (
    <div className="absolute inset-0 overflow-hidden">
      {[...Array(80)].map((_, i) => (
        <motion.span
          key={`star-${i}`}
          initial={{
            top: "100%", // Start from the bottom
            left: `${randomStart()}%`,
            opacity: 0.2,
            scale: 0.5,
          }}
          animate={{
            top: "0%", // Move to the top
            opacity: [0.5, 1, 0], // Fade in and out
            scale: [0.5, 1, 0.5],
          }}
          transition={{
            duration: randomMove() + 5, // Variable speed
            repeat: Infinity,
            ease: "linear",
          }}
          style={{
            position: "absolute",
            width: `2px`,
            height: `2px`,
            backgroundColor: "white",
            borderRadius: "50%",
            zIndex: 1,
          }}
          className="inline-block"
        ></motion.span>
      ))}
    </div>
  );
};

export const MemoizedStars = memo(Stars);
