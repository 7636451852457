"use client";
import { useFloat } from "@/lib";
import {
  GH_REPOSITORY_URL,
  RT_DEMO_CAL_URL,
  RT_LOGO_CTA,
  RT_LOGO_URL,
  RT_STUDIO_RIL,
} from "@/data";
import Logo from "@/components/ratl/utility/logo-nav";
import { useState, useEffect } from "react";
import CustomButton from "@/components/ratl/utility/CustonButton";
import { usePathname } from "next/navigation";

const sectionsOrder = ["Agents", "How it works", "Simple Steps", "Integration"];

const Navbar = () => {
  const pathname = usePathname();
  const isHomePage = pathname === "/";

  const { float: toggleNav, toggleFloat: setToggleNav } = useFloat(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isBelowIntegration, setIsBelowIntegration] = useState(false);
  const [currentSection, setCurrentSection] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { float, handleFloat } = useFloat([false, false]);

  // Update isScrolled and isBelowIntegration on scroll.
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setIsScrolled(scrollY > 100);

      // Determine if we've scrolled past the "Integration" section.
      const integrationEl = document.querySelector(
        `[data-section="Integration"]`
      );
      if (integrationEl) {
        const rect = integrationEl.getBoundingClientRect();
        // If the bottom of the Integration section is above the viewport, mark as below.
        setIsBelowIntegration(rect.bottom < 0);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Use IntersectionObserver to update the currentSection state.
  useEffect(() => {
    const sections = document.querySelectorAll("[data-section]");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const sectionName = (
              entry.target.getAttribute("data-section") || ""
            ).trim();
            setCurrentSection(sectionName);
          }
        });
      },
      { threshold: 0.5 }
    );
    sections.forEach((section) => observer.observe(section));
    return () => observer.disconnect();
  }, []);

  // Close the dropdown when the current section changes.
  useEffect(() => {
    setDropdownVisible(false);
  }, [currentSection]);

  // Compute visibility: show the mobile navbar if not scrolled,
  // OR if the current section is "Agents" or beyond,
  // OR if we've scrolled past the Integration section.
  const currentIndex = sectionsOrder.findIndex(
    (section) => section.toLowerCase() === currentSection.toLowerCase()
  );
  const agentsIndex = sectionsOrder.findIndex(
    (section) => section.toLowerCase() === "agents"
  );
  const showMobileNavbar =
    !isScrolled || currentIndex >= agentsIndex || isBelowIntegration;

  // Handler for scrolling to a section.
  const scrollToSection = (sectionName: string) => {
    const el = document.querySelector(`[data-section="${sectionName}"]`);
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
      setDropdownVisible(false);
    }
  };

  return (
    <>
      {/* Desktop Navbar */}
      <div
        className={`hidden lg:block text-white ${
          isScrolled
            ? "bg-[rgba(0,0,0,0.2)] backdrop-blur-lg"
            : "bg-transparent"
        } w-screen z-50 fixed`}
      >
        <div className="flex flex-row items-center justify-between mx-5 md:mx-[80px] md:py-6">
          <div className="flex lg:justify-between justify-center items-center">
            <a className="w-full" href={RT_LOGO_CTA}>
              <Logo logoSrc={RT_LOGO_URL} />
            </a>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <nav className="px-3 lg:px-6 text-[15.5px]">
              <ul className="flex lg:items-center lg:space-x-2">
                <li>
                  <a
                    href="/reflection2024"
                    className="text-sm flex items-center p-1 hover:text-neutral-300 text-rt-green-t1"
                  >
                    {"[ Reflection 2024 ]"}
                  </a>
                </li>
                <li>
                  <a
                    href="/about-us"
                    className="text-sm flex items-center p-1 text-white/70 hover:text-white"
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    href="/pricing"
                    className="text-sm flex items-center p-1 text-white/70 hover:text-white"
                  >
                    Pricing
                  </a>
                </li>
                <li>
                  <a
                    href="/use-cases"
                    className="text-sm flex items-center p-1 text-white/70 hover:text-white"
                  >
                    Use Cases
                  </a>
                </li>
                <li>
                  <a
                    href="https://docs.ratl.ai/guide"
                    target="_blank"
                    className="text-sm flex items-center p-1 text-white/70 hover:text-white"
                  >
                    Docs
                  </a>
                </li>
              </ul>
            </nav>
            <div>
              <CustomButton
                text={" Get Started for Free"}
                animationRequired={false}
                bgType={"solid"}
                onClick={() => window.open(RT_STUDIO_RIL, "_blank")}
                onMouseEnter={() => handleFloat(true, 1)}
                onMouseLeave={() => handleFloat(false, 1)}
                controller={float[1]}
                btnSize="small"
                isStudioBtn={false}
                className="px-4 py-2 text-sm transition w-fit"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Navbar */}
      {showMobileNavbar && (
        <div
          className={`lg:hidden fixed z-50 py-[12px] px-[16px] ${
            isScrolled && isHomePage
              ? "bg-gradient-to-b from-[#0C091C] to-[#141224] h-20 top-0 w-full"
              : "rounded-full border-[#c8c8c8]/25 border h-12 border-solid bg-[rgba(200,_200,_200,_0.10)] backdrop-filter backdrop-blur-[25px] top-5 left-5 right-5"
          }`}
        >
          {/* Navbar Content */}
          <div className="flex items-center justify-center h-full">
            {isScrolled && isHomePage ? (
              <>
                <div className="flex-grow flex justify-center items-center">
                  {dropdownVisible ? (
                    <a href="#hero-home-page">
                      <img
                        src="/ratl/logo/ratl_icon_white.png"
                        alt="ratl-logo"
                        className="w-10 h-auto"
                        width={93}
                        height={18}
                      />
                    </a>
                  ) : (
                    <div className="flex flex-col items-center justify-center gap-1">
                      {/* Display the current section name only if Integration is still in view */}
                      {!isBelowIntegration && (
                        <span className="text-white text-lg font-medium">
                          {currentSection || "Section"}
                        </span>
                      )}
                      <a
                        className="text-base hover:text-rt-purple-p2 text-rt-green-p1 hover:underline lg:hidden"
                        href="/reflection2024"
                      >
                        {"Reflection 2024"}
                      </a>
                    </div>
                  )}
                </div>
                {/* Dropdown toggle button */}
                <div className="absolute right-4">
                  <button onClick={() => setDropdownVisible(!dropdownVisible)}>
                    <svg
                      className={`w-6 h-6 text-white transition-transform duration-300 ${
                        dropdownVisible ? "rotate-180" : "rotate-0"
                      }`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>
                </div>
              </>
            ) : (
              // When not scrolled, show the main logo.
              <div className="flex justify-center items-center">
                <a href={RT_LOGO_CTA}>
                  <Logo logoSrc={RT_LOGO_URL} />
                </a>
              </div>
            )}
          </div>
          {/* Dropdown list */}
          {isScrolled && isHomePage && (
            <div
              className={`absolute left-0 right-0 top-full bg-gradient-to-b from-[#0C091C] to-[#141224] overflow-hidden transition-all duration-300 ${
                dropdownVisible ? "max-h-96 opacity-100" : "max-h-0 opacity-0"
              }`}
            >
              <ul className="flex flex-col">
                {sectionsOrder.map((sectionName) => {
                  // Do not highlight Integration if we've scrolled past it.
                  const isActive =
                    currentSection === sectionName &&
                    !(sectionName === "Integration" && isBelowIntegration);
                  return (
                    <li
                      key={sectionName}
                      className={`px-4 py-2 text-center text-white text-sm ${
                        isActive ? "bg-[#fdfdfd]/5 font-semibold" : ""
                      }`}
                    >
                      <button onClick={() => scrollToSection(sectionName)}>
                        {sectionName}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Navbar;
