"use client";

/* eslint-disable @next/next/no-img-element */
import { v4 as uuidv4 } from "uuid";
import {
  footerData,
  RT_FOOTER_BG,
  RT_FOOTER_BG_MOBILE,
  RT_ICON_RIGHT_ARROW,
  RT_LOGO_URL,
  RT_STUDIO_RIL,
} from "@/data";
import { MemoizedStars } from "@/components/ui/stars";
import Logo from "@/components/ratl/utility/logo-nav";
import { useRouter } from "next/navigation";
import { Badge } from "@/components/sections/PricingBody";
import CustomButton from "@/components/ratl/utility/CustonButton";
import { useFloat } from "@/lib";
import { FlipWords } from "@/components/ui/flip-words";
import Picture from "@/components/ratl/utility/Picture";
import OnlineStatusIndicator from "@/components/ui/status-indicator";

const Footer = () => {
  const { items, socials, ratlfooterlinks } = footerData;
  const router = useRouter();
  const { float, handleFloat } = useFloat([false, false]);
  const words = ["Mumbai", "Bengaluru", "Dubai", "San Jose"];

  return (
    <footer className="w-full">
      <div className="relative flex flex-col items-center justify-center text-white text-center px-6 md:my-[7.5rem] md:mb-[15rem]">
        <span>
          <Badge
            type="Let's Start Testing"
            arrow={true}
            className="h-9 text-[16px] px-5 py-0.5 mb-6"
          />
        </span>
        <div className="my-[36px] flex flex-col justify-center items-center gap-[14px]">
          <h1 className="text-[24px] md:text-[54px] md:text-5xl leading-[120%] font-bold md:font-normal">
            AI-powered platform to <br />
            <span className="gradient-text text-[28px] md:text-[54px]">
              revolutionize testing and reliability
            </span>
          </h1>
          <p className="md:mt-4 text-[24px] text-rt-purple-p6 max-w-3xl leading-[150%] font-inter">
            Smarter testing, stronger products. ratl.ai empowers teams with
            AI-driven reliability and seamless innovation.
          </p>
        </div>
        <div className="flex flex-col md:flex-row gap-4 justify-center items-center">
          <CustomButton
            text={"Contact Us"}
            animationRequired={false}
            bgType={"transparent"}
            onClick={() => router.push("/contact-us")}
            onMouseEnter={() => handleFloat(true, 1)}
            onMouseLeave={() => handleFloat(false, 1)}
            controller={float[1]}
            btnSize="small"
            isStudioBtn={false}
            showMobile={true}
          />

          <CustomButton
            text={" Get Started for Free"}
            animationRequired={false}
            bgType={"solid"}
            onClick={() => window.open(RT_STUDIO_RIL, "_blank")}
            onMouseEnter={() => handleFloat(true, 1)}
            onMouseLeave={() => handleFloat(false, 1)}
            controller={float[1]}
            btnSize="small"
            isStudioBtn={false}
            showMobile={true}
          />
        </div>
      </div>

      <div className="relative overflow-hidden md:max-h-[945px] max-h-[895px] flex flex-col items-center justify-end text-white">
        <div className="absolute inset-0 -z-40 flex justify-center items-center w-auto md:w-full">
          <MemoizedStars />
        </div>
        <img
          src={RT_FOOTER_BG}
          alt="Footer Background"
          className="transition h-[calc(100% - 1000px)] ease-in duration-200 -bottom-6 -z-30 w-full hidden md:block"
        />
        <img
          src={RT_FOOTER_BG_MOBILE}
          alt="Footer Background"
          className="transition absolute ease-in duration-200 h-full -z-30 w-full block md:hidden"
        />

        <div className="md:absolute top-40 text-left md:text-center w-full mt-[501px] md:mt-[0px] mx-5 pt-16 md:pt-0 md:max-w-[738px] px-4 text-rt-grey-t1 text-base font-normal md:top-1/3  md:transform max:-translate-y-1/2">
          <div className="flex md:mx-auto mb-7 md:justify-center items-center">
            <Logo logoSrc={RT_LOGO_URL} width={165} />
          </div>

          <p className="text-[16px] font-inter tracking-[0.5%] font-normal opacity-80 md:max-w-[738px] text-left md:text-center  mb-7 md:mb-0">
            Empower your testing with precision and speed. Welcome to the future
            of automated testing with ratl.ai, where efficiency meets
            intelligence.
          </p>

          <h3 className="lg:mt-9 md:mt-5 text-sm opacity-80 font-inter">
            Models Powered by
          </h3>
          <div className="flex items-start md:items-center justify-start md:justify-center gap-6 mt-2">
            <img
              src="/ratl/ratl-model-logos.png"
              alt="Models Powered by"
              className="md:w-[40%] w-[75%] h-auto object-contain"
            />
          </div>
        </div>

        <div className="md:hidden text-white my-10 w-full pl-5">
          <h3 className="text-rt-grey-t1 text-sm mb-[18px] font-inter">
            Explore
          </h3>
          <div className="grid grid-cols-2 text-rt-grey-t3 text-sm">
            <div className="flex flex-col space-y-2">
              <a href="/about-us" className="hover:underline">
                About Us
              </a>
              <a href="/pricing" className="hover:underline">
                Pricing
              </a>
            </div>
            <div className="flex flex-col space-y-2">
              <a href="/reflection2024" className="hover:underline">
                {" Reflection [2024]"}
              </a>
              <a href="/use-cases" className="hover:underline">
                Use cases
              </a>
            </div>
          </div>
        </div>

        <div className="md:absolute bottom-2 my-0 md:my-1 md:px-20 border-t-[1px] border-rt-grey-b1 md:border-none w-full text-sm flex flex-col md:flex md:flex-row-reverse gap-7 justify-center md:justify-between items-center md:items-end max-md:mb-3">
          <ul className="flex items-center max-md:mt-5">
            {socials.map((url) => (
              <li className="mr-4" key={uuidv4()}>
                <a href={url.href} target="_blank">
                  {url.icon}
                </a>
              </li>
            ))}
          </ul>
          <ul className="flex flex-col md:flex-row gap-1 items-center justify-center sm:mb-0 text-rt-grey-t1 flex-wrap text-sm">
            <li className="mr-3">&copy; 2025 ratl.ai - Fynd</li>
            <li
              onClick={() => router.push("/terms-of-use")}
              className="mr-3 cursor-pointer"
            >
              Terms of Use
            </li>
            <li
              onClick={() => router.push("/privacy-policy")}
              className="mr-3 cursor-pointer"
            >
              Privacy Policy
            </li>
            <Picture size={[19]} src={"/icons/footer/heart.png"} />
            <div className="flex lg:min-w-48 items-center"> 
              <FlipWords words={words} />
            </div>
            <div className="flex items-center gap-2">
              <OnlineStatusIndicator />
              <a href="https://ratl.betteruptime.com/">System Status</a>
            </div>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
